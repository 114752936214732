export const shouldUseReCapcha = (contentAreaItems: Array<any>): boolean => {
  if (contentAreaItems?.length < 1) return false;
  for (const i of contentAreaItems) {
    if (i?.hasOwnProperty("orderBlock") || i?.hasOwnProperty("formBlock")) {
      return true;
    }
    if (i?.hasOwnProperty("sectionblock")) {
      for (const j of i?.sectionblock?.columnAreaCollection?.items) {
        if (j?.hasOwnProperty("quizBlock")) {
          return true;
        }
      }
    }
  }
  return false;
};
