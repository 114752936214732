import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Tag from "@afa-shared/afa-components/dist/Tag";
import Text from "@afa-shared/afa-components/dist/Text";
import PageInformation from "@components/PageInformation/PageInformation";
import PrintButton from "@components/PrintButton";
import RichTextRenderer from "@components/RichTextRenderer";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import { NewsPageType_newspage } from "./queries/contentfulTypes/NewsPageType";

export interface ExtendedReportPage extends NewsPageType_newspage {
  latestReviewDate: string;
  pageUpdateDate: string;
}

const NewsPage = (props: any) => {
  const newsPage = props?.pageData as ExtendedReportPage;
  const imageData = newsPage?.ivMainImage?.[0];
  const publishDateString = newsPage?.publishDate;
  const context: Context = { ...defaultContext };
  const useReCapcha = shouldUseReCapcha(newsPage?.mainAreaCollection?.items);
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(newsPage?.mainAreaCollection?.items);

  const pageId = newsPage?.linkedFrom?.pageCollection?.items?.find((item) => item).sys?.id;

  return (
    <ArticlePageWrapper
      imageData={imageData}
      topImageText={newsPage?.topImageText}
      columns={8}
      relatedContentHeading={newsPage?.relatedContentHeading}
      relatedContentCollection={newsPage?.relatedContentCollection}
      relatedContentSmallImages={newsPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      contentfulPreviewEntryId={newsPage.sys.id}
      contentfulPreviewImageFieldId={"ivMainImage"}
      contentfulPreviewImageTextFieldId={"topImageText"}
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
    >
      <Grid container desktop={8}>
        <Heading
          variant="h1"
          data-contentful-entry-id={newsPage.sys.id}
          data-contentful-field-id="heading"
        >
          {newsPage?.heading}
        </Heading>
        <div className={pageStyles.tagAreaGrid}>
          <Tag
            label={newsPage?.newsType !== "Vanlig nyhet" ? newsPage?.newsType : "Nyhet"}
            data-contentful-entry-id={newsPage.sys.id}
            data-contentful-field-id="newsType"
          />
          {!newsPage?.hidePublishDate && (
            <Text
              variant="span"
              data-contentful-entry-id={newsPage.sys.id}
              data-contentful-field-id="publishDate"
            >
              Publicerad {publishDateString}
            </Text>
          )}
        </div>
        {newsPage?.preamble && (
          <Text
            variant={"preamble"}
            children={newsPage?.preamble}
            data-contentful-entry-id={newsPage.sys.id}
            data-contentful-field-id="preamble"
          />
        )}

        <div
          className={pageStyles.contentAreaGrid}
          data-contentful-entry-id={newsPage.sys.id}
          data-contentful-field-id="mainBody"
        >
          {newsPage?.mainBody?.json && (
            <RichTextRenderer
              json={newsPage?.mainBody?.json}
              links={newsPage?.mainBody?.links}
              pageTextArea
            />
          )}

          {newsPage?.mainAreaCollection?.items?.length > 0 && (
            <>
              <CFContentArea
                key="main-area"
                desktopColumns={1}
                mobileColumns={1}
                tabletColumns={1}
                contentAreaItems={newsPage?.mainAreaCollection?.items}
                maxWidth={getContentAreaMaxWidth(8)}
                context={context}
              />
            </>
          )}
        </div>
        <PrintButton />
        {(newsPage?.latestReviewDate || newsPage?.pageUpdateDate) && (
          <PageInformation
            pageId={pageId}
            latestReviewDate={newsPage?.latestReviewDate}
            pageUpdateDate={newsPage?.pageUpdateDate}
          />
        )}
      </Grid>
    </ArticlePageWrapper>
  );
};

export default NewsPage;
