import { IVImage, checkWebP, defaultImage } from "./imageHelper";

export const getHeroImage = (imageData: any, width: any): IVImage => {
  if (checkWebP) {
    const image = getHeroImageWebP(imageData, width);
    if (image) {
      return image;
    }
    return getHeroImageFallback(imageData, width);
  }
};

const getHeroImageWebP = (imageData: any, width: any): IVImage => {
  return imageData?.square
    ? ({
        url: imageData?.square,
        width: 375,
        height: 375,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
};

const getHeroImageFallback = (imageData: any, width: any): IVImage => {
  const desktopImage = imageData?.mediaUrl
    ? ({
        url: imageData?.mediaUrl,
        width: 1240,
        height: 720,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  return desktopImage || null;
};
