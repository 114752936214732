import { useEffect, useState } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import InsuranceInformationArea from "@components/InsuranceInformationArea/InsuranceInformationArea";
import InsuranceSector from "@components/InsuranceInformationArea/InsuranceSector/InsuranceSector";
import PageInformation from "@components/PageInformation/PageInformation";
import PrintButton from "@components/PrintButton";
import { InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items } from "@containers/InsuranceClaimPage/queries/contentfulTypes/InsuranceClaimPageType";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { PersonalInfo } from "types/PersonalInfo";
import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import {
  InsurancePageType_insurancepage,
  InsurancePageType_insurancepage_personalizedMessageFallback,
} from "./queries/contentfulTypes/InsurancePageType";

interface InsurancePageData extends InsurancePageType_insurancepage {
  urlToClaimPage: string;
  insuranceName: string;
  latestReviewDate: string;
  pageUpdateDate: string;
}

const InsurancePage = (props: any) => {
  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);
  const selectedSector: PersonalInfo = useSectorToggler(
    (state: ISectorToggler) => state.selectedSector
  );
  const insurancePage: InsurancePageData = props?.pageData;
  const [userSector, setUserSector] = useState<PersonalInfo>(null);
  const [isCoveredByInsurance, setIsCoveredByInsurance] = useState(false);
  const [personalizedMessage, setPersonalizedMessage] = useState<
    Array<InsurancePageType_insurancepage_personalizedMessageFallback>
  >(
    insurancePage?.personalizedMessageFallback
      ? Array.of(insurancePage?.personalizedMessageFallback)
      : []
  );

  const imageData = insurancePage?.ivMainImage?.[0];
  const urlToInsuranceClaimsPage = insurancePage?.urlToClaimPage;

  const context: Context = { ...defaultContext };

  context.insuranceName = insurancePage?.insuranceName;

  const useReCapcha = shouldUseReCapcha(insurancePage?.mainAreaCollection?.items);
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(
    insurancePage?.mainAreaCollection?.items
  );

  const restrictedRichTextBlock = personalizedMessage?.length > 0 && (
    <CFContentArea desktopColumns={1} contentAreaItems={personalizedMessage} context={context} />
  );

  useEffect(() => {
    if (isLoading) return;
    setIsCoveredByInsurance(
      insurancePage?.availableForAudiencesCollection?.items?.some(
        (a: any) => a?.audianceId === selectedSector?.audienceId
      )
    );
    const variantRichText = insurancePage?.personalizedMessageVariantsCollection?.items?.filter(
      (a: any) =>
        a?.restrictedRichTextBlock?.audienceCollection?.items?.some(
          (f: any) => f?.audience?.audianceId === selectedSector?.audienceId
        )
    );
    if (variantRichText && variantRichText?.length > 0) {
      setPersonalizedMessage(variantRichText);
    } else {
      setPersonalizedMessage(
        insurancePage?.personalizedMessageFallback
          ? Array.of(insurancePage?.personalizedMessageFallback)
          : []
      );
    }
    setUserSector(selectedSector);
  }, [isLoading, selectedSector]);

  const links: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items[] =
    [
      {
        __typename: "Link",
        linkText: insurancePage?.insuranceClaimButtonText,
        globalLinkItem: null,
        reference: null,
        url: urlToInsuranceClaimsPage,
        newTab: false,
        ikon: "arrow_forward",
        variant: "knapp",
        audienceCollection: null,
      },
    ];

  const pageId = insurancePage?.linkedFrom?.pageCollection?.items?.find((item) => item).sys?.id;
  return (
    <ArticlePageWrapper
      imageData={null}
      topImageText={""}
      columns={12}
      relatedContentHeading={insurancePage?.relatedContentHeading}
      relatedContentCollection={insurancePage?.relatedContentCollection}
      relatedContentSmallImages={insurancePage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      contentfulPreviewEntryId={insurancePage.sys.id}
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
    >
      <div className={pageStyles.wrapperGrid}>
        <div className={pageStyles.headingGrid}>
          <Heading
            variant="h1"
            removeMargin
            data-contentful-entry-id={insurancePage.sys.id}
            data-contentful-field-id="heading"
          >
            {insurancePage?.heading}
          </Heading>
          {insurancePage?.preamble && (
            <Text
              variant={"preamble"}
              children={insurancePage?.preamble}
              removeMargin
              data-contentful-entry-id={insurancePage.sys.id}
              data-contentful-field-id="preamble"
            />
          )}
        </div>
        <InsuranceSector
          sector={userSector?.sector}
          role={userSector?.role}
          context={context}
          state="about"
        />
        <InsuranceInformationArea
          state="about"
          covered={isCoveredByInsurance}
          hasAudience={userSector !== null}
          coveredHeading={insurancePage?.uspHeading}
          notCoveredHeading={insurancePage?.fallbackUspHeading}
          noAudienceFallbackHeading="Vad ingår i försäkringen?"
          informationMessage={restrictedRichTextBlock && restrictedRichTextBlock}
          bulletListItems={insurancePage?.uspar}
          links={links}
          context={context}
          image={imageData}
          contentfulPreviewEntryId={insurancePage.sys.id}
        />

        {insurancePage?.mainAreaCollection?.items?.length > 0 && (
          <Grid alignItems="center">
            <Grid container desktop={8}>
              <CFContentArea
                key={"insurancePage"}
                desktopColumns={1}
                mobileColumns={1}
                tabletColumns={1}
                contentAreaItems={insurancePage?.mainAreaCollection?.items}
                maxWidth={getContentAreaMaxWidth(8)}
                context={context}
              />
            </Grid>
          </Grid>
        )}
        <Grid alignItems="center">
          <Grid container desktop={8}>
            <PrintButton />
            {(insurancePage?.latestReviewDate || insurancePage?.pageUpdateDate) && (
              <PageInformation
                pageId={pageId}
                latestReviewDate={insurancePage?.latestReviewDate}
                pageUpdateDate={insurancePage?.pageUpdateDate}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </ArticlePageWrapper>
  );
};

export default InsurancePage;
