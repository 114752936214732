import { Suspense } from "react";

import PageContent, { IPageContent } from "./PageContent";
import pageWrapperStyles from "./PageWrapper.module.css";

const ArticlePageWrapper = ({
  imageData,
  pressImage,
  topImageText,
  columns,
  children,
  relatedContentHeading,
  relatedContentCollection,
  relatedContentSmallImages,
  context,
  useReCapcha,
  shouldRemoveTopMargin = false,
  shouldRemoveBottomMargin = false,
  pageWithHeroBlock = false,
  contentfulPreviewEntryId,
  contentfulPreviewImageFieldId,
  contentfulPreviewImageTextFieldId,
  contentfulPreviewRelatedContentHeadingFieldId,
  className,
}: IPageContent) => {
  return (
    <article className={`${pageWrapperStyles.pageWrapper} ${className}`}>
      <PageContent
        imageData={imageData}
        pressImage={pressImage}
        topImageText={topImageText}
        columns={columns}
        children={children}
        relatedContentHeading={relatedContentHeading}
        relatedContentCollection={relatedContentCollection}
        relatedContentSmallImages={relatedContentSmallImages}
        context={context}
        useReCapcha={useReCapcha}
        shouldRemoveTopMargin={shouldRemoveTopMargin}
        shouldRemoveBottomMargin={shouldRemoveBottomMargin}
        pageWithHeroBlock={pageWithHeroBlock}
        contentfulPreviewEntryId={contentfulPreviewEntryId}
        contentfulPreviewImageFieldId={contentfulPreviewImageFieldId}
        contentfulPreviewImageTextFieldId={contentfulPreviewImageTextFieldId}
        contentfulPreviewRelatedContentHeadingFieldId={
          contentfulPreviewRelatedContentHeadingFieldId
        }
        className={className}
      />
    </article>
  );
};

export default ArticlePageWrapper;
