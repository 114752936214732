import { SectionBlockType_sectionblock } from "@components/SectionBlock/queries/contentfulTypes/SectionBlockType";

const shouldRemoveBottomMargin = (contentAreaItems: Array<any>): boolean => {
  if (contentAreaItems?.length < 1) return false;
  const block = contentAreaItems?.[contentAreaItems?.length - 1];
  if (block?.hasOwnProperty("sectionblock")) {
    const sectionblock: SectionBlockType_sectionblock = block?.sectionblock;
    if (
      sectionblock.isWide &&
      (sectionblock.backgroundColor === "Ljusblå" || sectionblock.backgroundColor === "Ljusgrön")
    ) {
      return true;
    }
  }
  return false;
};

const shouldRemoveTopMargin = (contentAreaItems: Array<any>, useHero: boolean): boolean => {
  if (contentAreaItems?.length < 1 || !useHero) return false;
  const block = contentAreaItems[0];
  if (block?.hasOwnProperty("sectionblock")) {
    const sectionblock: SectionBlockType_sectionblock = block?.sectionblock;
    if (
      sectionblock.isWide &&
      (sectionblock.backgroundColor === "Ljusblå" || sectionblock.backgroundColor === "Ljusgrön")
    ) {
      return true;
    }
  }
  return false;
};

export { shouldRemoveTopMargin, shouldRemoveBottomMargin };
