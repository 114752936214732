// import Link from "next/link";
// import { Grid, Icon } from "@afa-shared/afa-components";
import { Grid } from "@afa-shared/afa-components";
import Text from "@afa-shared/afa-components/dist/Text";

import pageInfoStyles from "./PageInformation.module.scss";

interface IPageInformation {
  pageId?: string;
  latestReviewDate?: string;
  pageUpdateDate?: string;
}

export const PageInformation = ({ pageId, latestReviewDate, pageUpdateDate }: IPageInformation) => {
  return (
    <Grid className={pageInfoStyles.pageInformationWrapper}>
      <div className={pageInfoStyles.pageInformationDatesWrapper}>
        {pageUpdateDate && (
          <div
            className={pageInfoStyles.wrapper}
            data-contentful-entry-id={pageId}
            data-contentful-field-id="pageUpdateDate"
          >
            <Text variant="smallParagraph" className={pageInfoStyles.label}>
              <strong>Uppdaterad</strong>
            </Text>
            <Text variant="smallParagraph">{pageUpdateDate}</Text>
          </div>
        )}
        {latestReviewDate && (
          <div
            className={pageInfoStyles.wrapper}
            data-contentful-entry-id={pageId}
            data-contentful-field-id="latestReviewDate"
          >
            <Text variant="smallParagraph" className={pageInfoStyles.label}>
              <strong>Faktagranskad</strong>
            </Text>
            <Text variant="smallParagraph">{latestReviewDate}</Text>
          </div>
        )}
      </div>
      {/*TODO: Take back the code below when the page /om-oss/sa-jobbar-vi-med-texter exists*/}
      {/* {latestReviewDate && (
        <Link
          href={`${baseUrl}/om-oss/sa-jobbar-vi-med-texter`}
          prefetch={false}
          className={`${pageInfoStyles.reviewLink} ${pageInfoStyles.reviewLinkPadding}`}
        >
          <Icon
            name="arrow_forward"
            size="sm"
            referenceSize="link"
            className={pageInfoStyles.icon}
          />
          {"Så skriver och granskar vi texterna"}
        </Link>
      )} */}
    </Grid>
  );
};
export default PageInformation;
