import { getHeroImage } from "@utils/imageHelpers/HeroImageHelper";
import { defaultImage } from "@utils/imageHelpers/imageHelper";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import AfaImage from "./AfaImage";
import { IImageSrc } from "./ImageSources";

interface IImage {
  image: any;
  contentfulPreviewEntryId?: string;
  contentfulPreviewImageFieldId?: "ivImage" | "ivMainImage";
  className?: string;
}

const IvHeroImage = ({
  image,
  className,
  contentfulPreviewEntryId,
  contentfulPreviewImageFieldId,
}: IImage): JSX.Element => {
  const srcSet: IImageSrc[] = [{ url: replaceImageVaultBasePath(getHeroImage(image, 768)?.url) }];
  const metadata = getImageMetaData(image);

  return (
    <AfaImage
      fallbackUrl={replaceImageVaultBasePath(getHeroImage(image, 768)?.url) || null}
      alt={metadata?.altText || defaultImage?.imageAltText}
      imageSrcSet={srcSet}
      className={className}
      contentfulPreviewEntryId={contentfulPreviewEntryId}
      contentfulPreviewImageFieldId={contentfulPreviewImageFieldId}
    />
  );
};

export default IvHeroImage;
