import Icon from "@afa-shared/afa-components/dist/Icon";

import informationBoxStyles from "./InformationBox.module.css";

type InformationBoxType = "info" | "warning";

interface IInformationBoxProps {
  text: any;
  informationType?: InformationBoxType;
}

const InformationBox = ({ text, informationType = "info" }: IInformationBoxProps): JSX.Element => {
  const iconClass =
    informationType === "info" ? informationBoxStyles.info : informationBoxStyles.error;
  return (
    <div className={informationBoxStyles.informationBox}>
      <Icon
        size="sm"
        name={informationType === "info" ? "info" : "error"}
        referenceSize="span"
        className={`${informationBoxStyles.icon} ${iconClass}`}
      />
      <div className={informationBoxStyles.textWrapper}>{text}</div>
    </div>
  );
};

export default InformationBox;
