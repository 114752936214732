import { InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items } from "@containers/InsuranceClaimPage/queries/contentfulTypes/InsuranceClaimPageType";
import { IVImage } from "@utils/imageHelpers/imageHelper";

import { Context } from "types/context/Context";

import InformationArea from "../InformationArea/InformationArea";
import InformationAreaLink from "../InformationAreaLink";

export interface IInsuranceClaim {
  heading: string;
  image: IVImage;
  bulletListItems: string[];
  informationMessage: any;
  links: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items[];
  context: Context;
  contentfulPreviewEntryId?: string;
  contentfulPreviewHeadingFieldId?: string;
  contentfulPreviewUspsFieldId?: "usps";
}

const InsuranceClaim = ({
  heading,
  image,
  bulletListItems,
  informationMessage,
  links,
  context,
  contentfulPreviewEntryId,
  contentfulPreviewHeadingFieldId,
  contentfulPreviewUspsFieldId,
}: IInsuranceClaim) => {
  return (
    <InformationArea
      heading={heading}
      image={image}
      type={"claim"}
      bulletListType="bubbles"
      informationMessageType="info"
      bulletListItems={bulletListItems}
      informationMessage={informationMessage}
      buttonChildren={
        <>
          {links?.map(
            (
              link: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items,
              index: number
            ) => (
              <InformationAreaLink
                key={index}
                link={link}
                insurance={context?.insuranceName || ""}
                index={index}
                state={"claim"}
                dataTestId={"insurance-claim-link-" + index}
              />
            )
          )}
        </>
      }
      contentfulPreviewEntryId={contentfulPreviewEntryId}
      contentfulPreviewHeadingFieldId={contentfulPreviewHeadingFieldId}
      contentfulPreviewUspsFieldId={contentfulPreviewUspsFieldId}
    />
  );
};

export default InsuranceClaim;
