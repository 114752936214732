import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import HeroBlock from "@components/HeroBlock/Heroblock";
import PageInformation from "@components/PageInformation/PageInformation";
import PageWrapper from "@containers/Layout/PageWrapper/PageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import {
  shouldRemoveBottomMargin,
  shouldRemoveTopMargin,
} from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { getHeroData } from "@utils/pageHelpers/heroHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { HeroBlockType } from "types/HeroBlock";
import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import { LandingPageType_landingpage } from "./queries/contentfulTypes/LandingPageType";

export interface ExtendedLandingPage extends LandingPageType_landingpage {
  latestReviewDate: string;
  pageUpdateDate: string;
}

const LandingPage = (props: any) => {
  const landingPage = props?.pageData as ExtendedLandingPage;
  const imageData = landingPage?.ivMainImage?.[0];
  let context: Context = { ...defaultContext };
  const useReCapcha = shouldUseReCapcha(landingPage?.mainAreaCollection?.items);
  const heroBlock: HeroBlockType =
    landingPage?.heroContentAreaCollection?.items?.length > 0
      ? getHeroData(
          landingPage.heroContentAreaCollection?.items,
          landingPage?.heading,
          landingPage?.preamble,
          landingPage?.sys?.id
        )
      : null;

  const removeLayoutTopMargin = shouldRemoveTopMargin(
    landingPage?.mainAreaCollection?.items,
    heroBlock ? true : false
  );
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(landingPage?.mainAreaCollection?.items);
  const pageId = landingPage?.linkedFrom?.pageCollection?.items?.find((item) => item).sys?.id;
  return (
    <>
      {heroBlock && <HeroBlock heroBlock={heroBlock} />}
      <PageWrapper
        imageData={heroBlock ? null : imageData}
        topImageText={landingPage?.topImageText}
        columns={12}
        relatedContentHeading={landingPage?.relatedContentHeading}
        relatedContentCollection={landingPage?.relatedContentCollection}
        relatedContentSmallImages={landingPage?.relatedContentSmallImages}
        context={context}
        useReCapcha={useReCapcha}
        shouldRemoveTopMargin={removeLayoutTopMargin}
        shouldRemoveBottomMargin={removeLayoutBottomMargin}
        pageWithHeroBlock={heroBlock ? true : false}
        contentfulPreviewEntryId={landingPage.sys.id}
        contentfulPreviewImageFieldId={"ivMainImage"}
        contentfulPreviewImageTextFieldId={"topImageText"}
        contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
      >
        <Grid>
          {!heroBlock && (
            <Heading
              variant="h1"
              data-contentful-entry-id={landingPage.sys.id}
              data-contentful-field-id="heading"
            >
              {landingPage?.heading}
            </Heading>
          )}
          {!heroBlock && landingPage?.preamble && (
            <Text
              variant={"preamble"}
              children={landingPage?.preamble}
              data-contentful-entry-id={landingPage.sys.id}
              data-contentful-field-id="preamble"
            />
          )}
          {landingPage?.mainAreaCollection?.items?.length > 0 && (
            <CFContentArea
              key="main-area"
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={landingPage?.mainAreaCollection?.items}
              maxWidth={getContentAreaMaxWidth(12)}
              context={context}
            />
          )}
          {(landingPage?.latestReviewDate || landingPage?.pageUpdateDate) && (
            <PageInformation
              pageId={pageId}
              latestReviewDate={landingPage?.latestReviewDate}
              pageUpdateDate={landingPage?.pageUpdateDate}
            />
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};

export default LandingPage;
