import Icon from "@afa-shared/afa-components/dist/Icon";

import printButtonStyles from "./printButton.module.css";

const index = () => {
  return (
    <button className={printButtonStyles.printButtonContainer} onClick={() => print()}>
      <Icon name="print" size="sm" className={printButtonStyles.icon} /> Skriv ut
    </button>
  );
};
export default index;
