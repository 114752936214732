import clsx from "clsx";

import { Accordion } from "@afa-shared/afa-components";

import styles from "./AccessibilityList.module.scss";
import data from "./accessibilityListContent.json";

export type AccessibilityListItemType = {
  title: string;
  number: number;
  purpose: string | string[];
  statement: string | ContentProps;
  testing: {
    title: string;
    content: ContentProps[];
  };
  details: {
    title: string;
    content: ContentProps[];
  };
  role: string[];
  level: string[];
};

export default function AccessibilityList() {
  return (
    <div className={styles["accessibility-list"]}>
      {data.map((item, index) => (
        <ListItem key={`${item.title}-${index}`} {...item} />
      ))}
    </div>
  );
}

export const getArticleId = (title: string, number: number) => {
  return `${number}-${title}`
    .replace(/ /g, "-")
    .replace(/å/gi, "a")
    .replace(/ä/gi, "a")
    .replace(/ö/gi, "o")
    .toLowerCase();
};

const cx = clsx("accessibility-list__item", styles["accessibility-list__item"]);

function ListItem({
  title,
  number,
  purpose,
  statement,
  testing,
  details,
  role,
  level,
}: AccessibilityListItemType) {
  const articleId = getArticleId(title, number);
  return (
    <article id={articleId} className={cx}>
      <header className={styles["accessibility-list__item__header"]}>
        <div className={styles["accessibility-list__item__header-icon"]}>
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <polygon points="98.43,62.43 85.72,84.98 63.45,98.16 37.57,98.43 15.02,85.72 1.84,63.45 1.57,37.57 14.28,15.02 36.55,1.84 62.43,1.57 84.98,14.28 98.16,36.55" />
          </svg>
          <span className={styles["accessibility-list__item__header-number"]}>{number}</span>
        </div>
        <h2 className={styles["accessibility-list__item__header-title"]}>{title}</h2>
      </header>

      <div className={styles["accessibility-list__item__content"]}>
        <div className={styles["accessibility-list__item__content-section"]}>
          <strong className={styles["accessibility-list__item__content-title"]}>Syfte: </strong>
          {typeof purpose === "string" ? purpose : null}
        </div>
        <div className={styles["accessibility-list__item__content-section"]}>
          <strong className={styles["accessibility-list__item__content-title"]}>Påstående: </strong>
          {typeof statement === "string" ? statement : null}
        </div>
        <Accordion
          variant={"default"}
          data-blockname="expanderbart innehåll"
          className={styles["accessibility-list__item__accordion"]}
        >
          <div data-header={testing.title} data-headervariant="h4">
            {testing.content.map((item, index) => (
              <Content key={`${item.value}-${index}`} {...item} />
            ))}
          </div>
        </Accordion>
        <Accordion
          variant={"default"}
          data-blockname="expanderbart innehåll"
          className={styles["accessibility-list__item__accordion"]}
        >
          <div data-header={details.title} data-headervariant="h4">
            {details.content.map((item, index) => (
              <Content key={`${item.value}-${index}`} {...item} />
            ))}
          </div>
        </Accordion>

        <TagList title="Roll" tags={role} />
        <TagList title="Nivå" tags={level} />
      </div>
    </article>
  );
}

type ContentProps = {
  type: string;
  value?: string;
  content?: any;
  href?: string;
  listType?: string;
  heading?: string;
  isListItem?: boolean;
};

function Content({ type, value, heading, content, href, listType, isListItem }: ContentProps) {
  function mapContent(contentItem: ContentProps, index: number) {
    return <Content key={`${contentItem.value}-${index}`} {...contentItem} />;
  }

  function renderContent() {
    switch (type) {
      case "heading":
        return (
          <h2 className={styles["accessibility-list__item__content-section-heading"]}>{value}</h2>
        );
      case "paragraph":
        if (content) {
          return (
            <p className={styles["accessibility-list__item__content-section-paragraph"]}>
              {content.map(mapContent)}
            </p>
          );
        }
        return (
          <p className={styles["accessibility-list__item__content-section-paragraph"]}>{value}</p>
        );
      case "text":
        return <span>{value}</span>;
      case "list":
        const ListElement = listType === "numbered" ? "ol" : "ul";
        return (
          <>
            {heading && <span>{heading}</span>}
            <ListElement className={styles["accessibility-list__item__content-section-list"]}>
              {content.map((contentItem: ContentProps, index: number) => (
                <Content key={`${contentItem.value}-${index}`} {...contentItem} isListItem />
              ))}
            </ListElement>
          </>
        );
      case "link":
        return (
          <a href={href} className={styles["accessibility-list__item__content-section-link"]}>
            {value}
          </a>
        );
      default:
        return null;
    }
  }

  return isListItem ? <li key={value}>{renderContent()}</li> : renderContent();
}

export const TagList = ({ title, tags }: { title: string; tags: string[] }) => (
  <div className={styles["accessibility-list__item__tags"]}>
    <p>{title}:</p>
    <span className={styles["accessibility-list__item__tags-list"]}>
      {tags.map((tag, index) => (
        <p key={`${tag}-${index}`}>{tag}</p>
      ))}
    </span>
  </div>
);
