import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";
import { AccessibilityListItemType, getArticleId } from "@components/AccessibilityList";
import { generateUUID } from "@utils/uuid";

import leftMenuStyles from "./StandardPageLeftMenu.module.css";

export interface ILeftMenuItem {
  id: string;
  name: string;
  slug: string;
  url: string;
  level: number;
  children: Array<ILeftMenuItem>;
  accessibilityList: Array<AccessibilityListItemType>;
}

export const StandardPageLeftMenu = (props) => {
  const firstLevelItems = props?.leftmenuItems as ILeftMenuItem[];
  const router = useRouter();
  const [displayChildren, setDisplayChildren] = useState<any>({});
  const ref = useRef(null);

  useEffect(() => {
    firstLevelItems?.map((item: ILeftMenuItem) => {
      openSubMenuRecursivly(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.slug]);

  const openSubMenuRecursivly = (item: ILeftMenuItem) => {
    if (itemIsActive(item) || hasActiveChildren(item)) {
      openChildren(item);
      item?.children?.map((child: ILeftMenuItem) => openSubMenuRecursivly(child));
    } else {
      setDisplayChildren((displayChildren) => {
        return {
          ...displayChildren,
          [item.id]: false,
        };
      });
    }
  };

  const itemIsActive = (item: ILeftMenuItem): boolean => item.url === router.asPath;
  const hasActiveChildren = (item: ILeftMenuItem): boolean =>
    item.children?.some((child: ILeftMenuItem) =>
      itemIsActive(child) ? true : hasActiveChildren(child)
    );

  const openChildren = (item: ILeftMenuItem) => {
    setDisplayChildren((displayChildren) => {
      return {
        ...displayChildren,
        [item.id]: true,
      };
    });
  };

  const handleClick = (item: ILeftMenuItem) => {
    setDisplayChildren({
      ...displayChildren,
      [item.id]: !displayChildren[item.id],
    });
  };

  const GetLeftMenuItem = (item: ILeftMenuItem, level: number): JSX.Element => {
    const hasChildren = item?.children?.length > 0;
    const areaId = generateUUID();

    return (
      <li className={leftMenuStyles.listItem} key={item.id} data-level={level}>
        <div className={leftMenuStyles.itemWrapper}>
          <Link href={item?.url} prefetch={false} legacyBehavior={true}>
            <a
              href={item?.url}
              className={`${leftMenuStyles.link} ${
                itemIsActive(item) ? leftMenuStyles.active : ""
              } ${hasActiveChildren(item) ? leftMenuStyles.bold : ""}`}
            >
              <span
                className={`${leftMenuStyles.decorator} ${
                  itemIsActive(item) ? leftMenuStyles.active : ""
                }`}
                data-level={level}
              ></span>
              <span
                className={`${leftMenuStyles.span} ${
                  item?.level === 0 ? leftMenuStyles.levelZeroLabel : ""
                }`}
              >
                {item?.name}
              </span>
            </a>
          </Link>

          {hasChildren ? (
            <button
              className={`${leftMenuStyles.iconButton} ${
                hasChildren ? leftMenuStyles.hasChildren : ""
              }`}
              onClick={() => handleClick(item)}
              aria-expanded={displayChildren[item.id] ? displayChildren[item.id] : false}
              aria-controls={areaId}
              aria-label={`Växla undermeny för ${item?.name}`}
            >
              <Icon name={displayChildren[item.id] ? "remove" : "add"} size="sm" />
            </button>
          ) : null}
        </div>

        {hasChildren && (
          <div
            className={`${leftMenuStyles.childWrapper} ${
              displayChildren[item?.id] ? leftMenuStyles.isOpen : ""
            }`}
            data-level={level}
            id={areaId}
          >
            <ul className={leftMenuStyles.list} ref={ref}>
              {item?.children?.map((childItem: ILeftMenuItem) => {
                return GetLeftMenuItem(childItem, level + 1);
              })}
            </ul>
          </div>
        )}
      </li>
    );
  };

  return (
    <nav
      aria-label={"Vänstermeny"}
      style={{ position: "relative", height: "100%" }}
      data-contentful-entry-id={props.contentfulPreviewEntryId}
      data-contentful-field-id={props.contentfulPreviewLeftMenuFieldId}
    >
      <ul className={leftMenuStyles.leftMenu} data-level={1}>
        <li className={leftMenuStyles.listItem} data-level={0}>
          <div className={leftMenuStyles.itemWrapper}>
            <Link href={firstLevelItems[0]?.url} legacyBehavior={true}>
              <a
                href={firstLevelItems[0]?.url}
                className={`${leftMenuStyles.link} ${
                  router.asPath === firstLevelItems[0]?.url ? leftMenuStyles.active : ""
                }`}
              >
                <span
                  className={`${leftMenuStyles.decorator} ${
                    router.asPath === firstLevelItems[0]?.url ? leftMenuStyles.active : ""
                  }`}
                  data-level={0}
                ></span>
                <span className={leftMenuStyles.span}>{firstLevelItems[0]?.name}</span>
              </a>
            </Link>
          </div>
        </li>
        {firstLevelItems[0]?.children?.map((item: ILeftMenuItem) => GetLeftMenuItem(item, 0))}
      </ul>

      {props.accessibilityList && (
        <ol
          style={{
            position: "sticky",
            top: "1rem",
            overflowY: "scroll",
            maxHeight: "100vh",
          }}
        >
          {props.accessibilityList.map((item: any, index: number) => {
            const articleId = getArticleId(item.title, item.number);
            return (
              <li key={`${articleId}-${index}`}>
                <a href={`#${articleId}`}>{item.title}</a>
              </li>
            );
          })}
        </ol>
      )}
    </nav>
  );
};
export default StandardPageLeftMenu;
