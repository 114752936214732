import DateSign from "@afa-shared/afa-components/dist/DateSign";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Message from "@afa-shared/afa-components/dist/Message";
import Tag from "@afa-shared/afa-components/dist/Tag";
import Text from "@afa-shared/afa-components/dist/Text";
import PrintButton from "@components/PrintButton";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { getDateOrder, getRegistrationButtonOrder } from "@utils/eventHelpers/getDateOrder";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import eventPageStyles from "./EventPage.module.css";
import { EventPageType_eventpage } from "./queries/contentfulTypes/EventPageType";

export interface ExtendedEventData extends EventPageType_eventpage {
  dateSignDay: string;
  dateSignMonth: string;
  isPastEvent: boolean;
  showEventInfo: boolean;
  physicalRegistrationIsOpen: boolean;
  remoteRegistrationIsOpen: boolean;
  lastRegistrationDatePhysicalString: string;
  lastRegistrationDateRemoteString: string;
  eventDatesStringArray: string[];
  eventShortDate: string;
}

const EventPage = (props: any) => {
  const eventPage: ExtendedEventData = props?.pageData;
  const imageData = eventPage?.ivMainImage?.[0];
  const registrationLinkPhysical = eventPage?.registrationLink;
  const registrationLinkRemote = eventPage?.registrationLinkTwo;
  const linkTypePhysical = getInternalOrExternalIcon(registrationLinkPhysical?.url, null, null);
  const linkTypeRemote = getInternalOrExternalIcon(registrationLinkRemote?.url, null, null);
  const context: Context = { ...defaultContext };
  const useReCapcha = shouldUseReCapcha(
    [].concat(eventPage?.mainAreaCollection?.items, eventPage?.mainAreaTopCollection?.items)
  );
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(eventPage?.mainAreaCollection?.items);

  const physicalRegistrationLinkExists = eventPage && registrationLinkPhysical !== null;
  const remoteRegistrationLinkExists = eventPage && registrationLinkRemote !== null;
  const showBothRegistrationLinks = physicalRegistrationLinkExists && remoteRegistrationLinkExists;
  const isSameDate =
    eventPage?.lastRegistrationDatePhysicalString === eventPage?.lastRegistrationDateRemoteString;

  return (
    <ArticlePageWrapper
      imageData={imageData}
      topImageText={eventPage?.topImageText}
      columns={8}
      relatedContentHeading={eventPage?.relatedContentHeading}
      relatedContentCollection={eventPage?.relatedContentCollection}
      relatedContentSmallImages={eventPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      className={eventPageStyles.eventPage}
      contentfulPreviewEntryId={eventPage.sys.id}
      contentfulPreviewImageFieldId={"ivMainImage"}
      contentfulPreviewImageTextFieldId="topImageText"
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
    >
      <Grid container desktop={8}>
        <div style={{ position: "relative" }}>
          <div className={eventPageStyles.dateSignWrapper}>
            <DateSign day={eventPage?.dateSignDay} month={eventPage?.dateSignMonth} />
          </div>
        </div>
        <Heading
          className={eventPageStyles.heading}
          variant="h1"
          data-contentful-entry-id={eventPage.sys.id}
          data-contentful-field-id="heading"
        >
          {eventPage?.heading}
        </Heading>
        <div className={eventPageStyles.metaWrapper}>
          <div className={eventPageStyles.tagWrapper}>
            <Tag
              label={eventPage?.eventCategory}
              data-contentful-entry-id={eventPage.sys.id}
              data-contentful-field-id="eventCategory"
            />
            {eventPage?.eventFormat && (
              <Tag
                label={eventPage?.eventFormat}
                data-contentful-entry-id={eventPage.sys.id}
                data-contentful-field-id="eventFormat"
              />
            )}
          </div>
          {!eventPage?.isPastEvent ? (
            <div
              className={eventPageStyles.dateWrapper}
              data-contentful-entry-id={eventPage.sys.id}
              data-contentful-field-id="endDate"
            >
              {eventPage?.eventDatesStringArray?.map((item, index) => (
                <Text variant="span" key={index}>
                  {item}
                </Text>
              ))}
            </div>
          ) : (
            <Text
              variant="span"
              data-contentful-entry-id={eventPage.sys.id}
              data-contentful-field-id="startDate"
            >{`Genomfördes ${eventPage?.eventShortDate}`}</Text>
          )}
        </div>
        {eventPage?.showEventInfo && (
          <div className={eventPageStyles.infoWrapper}>
            {eventPage?.eventShortInfo && (
              <Grid justifyContent="flex-end">
                <Text
                  variant="paragraph"
                  removeMargin
                  children={eventPage?.eventShortInfo}
                  data-contentful-entry-id={eventPage.sys.id}
                  data-contentful-field-id="eventShortInfo"
                />
              </Grid>
            )}

            {showBothRegistrationLinks && (
              <Grid
                direction="column"
                data-contentful-entry-id={eventPage.sys.id}
                data-contentful-field-id="endDateForRegistration"
              >
                <Grid>
                  <Text variant="paragraph" removeMargin>
                    <>
                      {isSameDate ? (
                        eventPage?.physicalRegistrationIsOpen ? (
                          <div>
                            Anmäl dig senast {eventPage?.lastRegistrationDatePhysicalString}
                          </div>
                        ) : null
                      ) : (
                        <div>
                          <div>Anmäl dig senast:</div>
                          <ul>
                            {(eventPage?.physicalRegistrationIsOpen ||
                              eventPage?.remoteRegistrationIsOpen) &&
                              getDateOrder(eventPage)}
                          </ul>
                        </div>
                      )}
                    </>
                  </Text>
                </Grid>
                <div className={eventPageStyles.registrationWrapper}>
                  {getRegistrationButtonOrder(eventPage, linkTypePhysical, linkTypeRemote)}
                </div>
              </Grid>
            )}

            {physicalRegistrationLinkExists &&
              !showBothRegistrationLinks &&
              eventPage?.physicalRegistrationIsOpen && (
                <Grid direction="column">
                  <Grid>
                    <Text
                      variant="paragraph"
                      removeMargin
                      children={
                        "Anmäl dig senast " +
                        eventPage?.lastRegistrationDatePhysicalString +
                        " för att delta på plats."
                      }
                    />
                  </Grid>
                  <div className={eventPageStyles.registrationWrapper}>
                    {registrationLinkPhysical?.url && (
                      <TrackedLink
                        linkType={linkTypePhysical}
                        linkText={registrationLinkPhysical?.linkText}
                        url={registrationLinkPhysical?.url}
                        linkVariant={"button"}
                        hideIcon={registrationLinkPhysical?.url ? false : true}
                        target={registrationLinkPhysical?.newTab ? "_blank" : "_self"}
                      />
                    )}
                  </div>
                </Grid>
              )}

            {remoteRegistrationLinkExists &&
              !showBothRegistrationLinks &&
              eventPage?.remoteRegistrationIsOpen && (
                <Grid direction="column">
                  <Grid>
                    <Text
                      variant="paragraph"
                      removeMargin
                      children={
                        "Anmäl dig senast " +
                        eventPage?.lastRegistrationDateRemoteString +
                        " för att delta digitalt."
                      }
                    />
                  </Grid>
                  <div className={eventPageStyles.registrationWrapper}>
                    {registrationLinkRemote?.url && (
                      <TrackedLink
                        linkType={linkTypeRemote}
                        linkText={registrationLinkRemote?.linkText}
                        url={registrationLinkRemote?.url}
                        linkVariant={"button"}
                        hideIcon={registrationLinkRemote?.url ? false : true}
                        target={registrationLinkRemote?.newTab ? "_blank" : "_self"}
                      />
                    )}
                  </div>
                </Grid>
              )}
          </div>
        )}

        {(eventPage?.endDateForRegistrationRemote || eventPage?.endDateForRegistration) &&
          !eventPage?.physicalRegistrationIsOpen &&
          !eventPage?.remoteRegistrationIsOpen &&
          !eventPage?.isPastEvent && (
            <Message
              type="info"
              heading={"Det går inte längre att anmäla sig. Sista anmälningsdagen har passerat."}
              headingVariant="paragraph"
              className={eventPageStyles.infoWrapper}
            />
          )}

        {eventPage?.preamble && (
          <Text
            variant={"preamble"}
            children={eventPage?.preamble}
            data-contentful-entry-id={eventPage.sys.id}
            data-contentful-field-id="preamble"
          />
        )}
        <div className={pageStyles.contentAreaGrid}>
          {/* Main area for recorded events (videoblock) */}
          {eventPage?.mainAreaTopCollection?.items?.length > 0 && (
            <CFContentArea
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              key="main-area"
              contentAreaItems={eventPage?.mainAreaTopCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          )}
          {eventPage?.mainBody?.json && (
            <div data-contentful-entry-id={eventPage.sys.id} data-contentful-field-id="mainBody">
              <RichTextRenderer
                json={eventPage?.mainBody?.json}
                links={eventPage?.mainBody?.links}
                pageTextArea
              />
            </div>
          )}

          {eventPage?.mainAreaCollection?.items?.length > 0 && (
            <>
              <CFContentArea
                desktopColumns={1}
                mobileColumns={1}
                tabletColumns={1}
                key="main-area"
                contentAreaItems={eventPage?.mainAreaCollection?.items}
                maxWidth={getContentAreaMaxWidth(8)}
                context={context}
              />
            </>
          )}
        </div>
        <PrintButton />
      </Grid>
    </ArticlePageWrapper>
  );
};

export default EventPage;
