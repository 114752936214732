import { ReactNode } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import IvHeroImage from "@components/Medias/Image/IvHeroImage";
import { IVImage } from "@utils/imageHelpers/imageHelper";

import ExtendedBulletList from "../ExtendedBulletList/ExtendedBulletList";
import InformationBox from "../InformationBox/InformationBox";
import informationAreaStyles from "./InformationArea.module.css";

interface IInformationAreaProps {
  heading: string;
  image: IVImage;
  type: "about" | "claim" | "noAudience" | "notCovered";
  bulletListItems?: string[];
  bulletListType?: "check" | "bubbles";
  informationMessage?: string;
  informationMessageType?: "info" | "warning";
  buttonChildren?: ReactNode;
  contentfulPreviewEntryId?: string;
  contentfulPreviewHeadingFieldId?: string;
  contentfulPreviewUspsFieldId?: "usps" | "uspar";
}

const InformationArea = ({
  heading,
  image,
  type,
  bulletListItems,
  bulletListType,
  informationMessage,
  informationMessageType,
  buttonChildren,
  contentfulPreviewEntryId,
  contentfulPreviewHeadingFieldId,
  contentfulPreviewUspsFieldId,
}: IInformationAreaProps): JSX.Element => {
  const useTabletImage =
    (informationMessage && bulletListItems) ||
    (informationMessage && buttonChildren) ||
    (bulletListItems && buttonChildren);
  const getTypeClass = () => {
    switch (type) {
      case "about":
        return informationAreaStyles.about;
      case "claim":
        return informationAreaStyles.claim;
      case "notCovered":
        return informationAreaStyles.notCovered;
      default:
        return "";
    }
  };

  const typeClass = getTypeClass();
  return (
    <div
      className={`${informationAreaStyles.informationArea} ${typeClass} ${
        useTabletImage ? informationAreaStyles.useTabletImage : ""
      } ${type === "noAudience" ? informationAreaStyles.noBackground : ""}`}
    >
      <div className={informationAreaStyles.contentArea}>
        <div className={informationAreaStyles.contentWrapper}>
          <div className={informationAreaStyles.content}>
            <div className={informationAreaStyles.topWrapper}>
              <Heading
                variant="h2"
                children={heading}
                removeMargin
                data-contentful-entry-id={contentfulPreviewEntryId}
                data-contentful-field-id={contentfulPreviewHeadingFieldId}
              />
              {bulletListItems && (
                <ExtendedBulletList
                  bulletType={bulletListType || "check"}
                  listItems={bulletListItems}
                  contentfulPreviewEntryId={contentfulPreviewEntryId}
                  contentfulPreviewUspsFieldId={contentfulPreviewUspsFieldId}
                />
              )}
            </div>
            <div className={informationAreaStyles.tabletImageWrapper}>
              <IvHeroImage
                image={image}
                className={informationAreaStyles.ivHeroImage}
                contentfulPreviewEntryId={contentfulPreviewEntryId}
                contentfulPreviewImageFieldId={"ivMainImage"}
              />
            </div>
          </div>
          {informationMessage && (
            <InformationBox
              text={informationMessage}
              informationType={informationMessageType || "info"}
            />
          )}
        </div>

        {buttonChildren && (
          <div className={informationAreaStyles.buttonWrapper}>{buttonChildren}</div>
        )}
      </div>
      <IvHeroImage
        image={image}
        className={informationAreaStyles.ivHeroImage}
        contentfulPreviewEntryId={contentfulPreviewEntryId}
        contentfulPreviewImageFieldId={"ivMainImage"}
      />
    </div>
  );
};

export default InformationArea;
