import bulletListStyles from "./ExtendedBulletList.module.css";

type BulletType = "check" | "bubbles";

interface IExtendedBulletListProps {
  bulletType: BulletType;
  listItems: string[];
  contentfulPreviewEntryId?: string;
  contentfulPreviewUspsFieldId?: "usps" | "uspar";
}

const ExtendedBulletList = ({
  bulletType = "check",
  listItems,
  contentfulPreviewEntryId,
  contentfulPreviewUspsFieldId,
}: IExtendedBulletListProps): JSX.Element => {
  const bulletClass = bulletType === "check" ? bulletListStyles.check : bulletListStyles.bubbles;
  return (
    <div className={bulletListStyles.bulletList}>
      {bulletType === "check" ? (
        <ul
          className={bulletListStyles.list}
          data-contentful-entry-id={contentfulPreviewEntryId}
          data-contentful-field-id={contentfulPreviewUspsFieldId}
        >
          {listItems?.map((item, index) => (
            <li
              className={`${bulletListStyles.listItem} ${bulletClass}`}
              key={index}
              data-number={index + 1}
            >
              <span>{item}</span>
            </li>
          ))}
        </ul>
      ) : (
        <ol
          className={bulletListStyles.list}
          data-contentful-entry-id={contentfulPreviewEntryId}
          data-contentful-field-id={contentfulPreviewUspsFieldId}
        >
          {listItems?.map((item, index) => (
            <li
              className={`${bulletListStyles.listItem} ${bulletClass}`}
              key={index}
              data-number={index + 1}
            >
              <span>{item}</span>
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default ExtendedBulletList;
