import React from "react";

import TrackedLink from "@components/TrackedLink";
import { ExtendedEventData } from "@containers/EventPage";
import { LinkType } from "@utils/linkIconHelper";

export const getDateOrder = (eventPage: ExtendedEventData) => {
  const remoteRegistrationDate = new Date(eventPage?.lastRegistrationDateRemoteString);
  const physicalRegistrationDate = new Date(eventPage?.lastRegistrationDatePhysicalString);
  if (eventPage?.physicalRegistrationIsOpen && eventPage?.remoteRegistrationIsOpen) {
    if (physicalRegistrationDate < remoteRegistrationDate) {
      return (
        <>
          <li>{eventPage?.lastRegistrationDatePhysicalString} för att delta på plats.</li>
          <li>{eventPage?.lastRegistrationDateRemoteString} för att delta digitalt.</li>
        </>
      );
    } else {
      return (
        <>
          <li>{eventPage?.lastRegistrationDateRemoteString} för att delta digitalt.</li>
          <li>{eventPage?.lastRegistrationDatePhysicalString} för att delta på plats.</li>
        </>
      );
    }
  } else if (eventPage?.physicalRegistrationIsOpen) {
    return (
      <>
        <li>{eventPage?.lastRegistrationDatePhysicalString} för att delta på plats.</li>
        <li>Det är inte längre möjligt att anmäla sig för att delta digitalt.</li>
      </>
    );
  } else {
    return (
      <>
        <li>{eventPage?.lastRegistrationDateRemoteString} för att delta digitalt.</li>
        <li>Det är inte längre möjligt att anmäla sig för att delta på plats.</li>
      </>
    );
  }
};

export const getRegistrationButtonOrder = (
  eventPage: ExtendedEventData,
  linkTypePhysical: LinkType,
  linkTypeRemote: LinkType
) => {
  const remoteRegistrationDate = new Date(eventPage?.lastRegistrationDateRemoteString);
  const physicalRegistrationDate = new Date(eventPage?.lastRegistrationDatePhysicalString);
  const showBothButtons = !!(
    eventPage?.physicalRegistrationIsOpen &&
    eventPage?.registrationLink?.url &&
    eventPage?.remoteRegistrationIsOpen &&
    eventPage?.registrationLinkTwo?.url
  );

  if (showBothButtons) {
    if (physicalRegistrationDate < remoteRegistrationDate) {
      return (
        <>
          <TrackedLink
            linkType={linkTypePhysical}
            linkText={eventPage?.registrationLink?.linkText}
            url={eventPage?.registrationLink?.url}
            linkVariant={"button"}
            hideIcon={eventPage?.registrationLink?.url ? false : true}
            target={eventPage?.registrationLink?.newTab ? "_blank" : "_self"}
          />
          <TrackedLink
            linkType={linkTypeRemote}
            linkText={eventPage?.registrationLinkTwo?.linkText}
            url={eventPage?.registrationLinkTwo?.url}
            linkVariant={"button"}
            hideIcon={eventPage?.registrationLinkTwo?.url ? false : true}
            target={eventPage?.registrationLinkTwo?.newTab ? "_blank" : "_self"}
          />
        </>
      );
    } else {
      return (
        <>
          <TrackedLink
            linkType={linkTypeRemote}
            linkText={eventPage?.registrationLinkTwo?.linkText}
            url={eventPage?.registrationLinkTwo?.url}
            linkVariant={"button"}
            hideIcon={eventPage?.registrationLinkTwo?.url ? false : true}
            target={eventPage?.registrationLinkTwo?.newTab ? "_blank" : "_self"}
          />
          <TrackedLink
            linkType={linkTypePhysical}
            linkText={eventPage?.registrationLink?.linkText}
            url={eventPage?.registrationLink?.url}
            linkVariant={"button"}
            hideIcon={eventPage?.registrationLink?.url ? false : true}
            target={eventPage?.registrationLink?.newTab ? "_blank" : "_self"}
          />
        </>
      );
    }
  } else if (eventPage?.physicalRegistrationIsOpen && !eventPage?.remoteRegistrationIsOpen) {
    return (
      <>
        <TrackedLink
          linkType={linkTypePhysical}
          linkText={eventPage?.registrationLink?.linkText}
          url={eventPage?.registrationLink?.url}
          linkVariant={"button"}
          hideIcon={eventPage?.registrationLink?.url ? false : true}
          target={eventPage?.registrationLink?.newTab ? "_blank" : "_self"}
        />
      </>
    );
  } else if (!eventPage?.physicalRegistrationIsOpen && eventPage?.remoteRegistrationIsOpen) {
    return (
      <>
        <TrackedLink
          linkType={linkTypePhysical}
          linkText={eventPage?.registrationLinkTwo?.linkText}
          url={eventPage?.registrationLinkTwo?.url}
          linkVariant={"button"}
          hideIcon={eventPage?.registrationLinkTwo?.url ? false : true}
          target={eventPage?.registrationLinkTwo?.newTab ? "_blank" : "_self"}
        />
      </>
    );
  }
};
