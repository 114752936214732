import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Text from "@afa-shared/afa-components/dist/Text";
import { sectorViewTracker } from "@components/sectorGuide/sectorTracker";

import useParamsToggler from "hooks/useParamToggler";
import { IRole, ISector } from "types/PersonalInfo";
import { Context } from "types/context/Context";

import InformationBox from "../InformationBox/InformationBox";
import insuranceSectorStyles from "./InsuranceSector.module.css";

export interface IInsuranceSector {
  sector?: ISector;
  role?: IRole;
  context: Context;
  state: "claim" | "about";
}

const InsuranceSector = ({ sector, role }: IInsuranceSector) => {
  const { appendQueryParameter } = useParamsToggler();
  let sectorName;
  let roleString;
  if (sector && role) {
    roleString =
      role?.roleId === "arbetsgivareochfack" && sector?.sectorId !== "egen-foretagare"
        ? "Anpassat för dig som är arbetsgivare eller facklig representant för"
        : "Informationen gäller för";
    sectorName = sector?.sectorGuideModal;
  }

  const handleClick = () => {
    appendQueryParameter("openSector", "true");
    sectorViewTracker("målgruppsväljare_pop_up");
  };

  return (
    <div className={insuranceSectorStyles.insuranceSector}>
      {sector ? (
        <div className={insuranceSectorStyles.linkWrapper}>
          {roleString} <span>{sectorName}</span>
          <button
            className={insuranceSectorStyles.link}
            onClick={() => {
              handleClick();
            }}
          >
            Ändra
          </button>
        </div>
      ) : (
        <div className={insuranceSectorStyles.fallbackWrapper}>
          <InformationBox
            informationType="warning"
            text={
              <Text
                variant="span"
                children={
                  "Den här sidan finns i olika varianter för anställda och arbetsgivare. Anpassa sidan så att du får rätt information."
                }
              />
            }
          />
          <Button
            label={"Välj vad som visas"}
            buttonType="primary"
            onClick={() => {
              handleClick();
            }}
            className={insuranceSectorStyles.selectButton}
          />
        </div>
      )}
    </div>
  );
};

export default InsuranceSector;
