import { IVImage } from "@utils/imageHelpers/imageHelper";

import InformationArea from "../InformationArea/InformationArea";

export interface IInsuranceNoAudience {
  heading: string;
  image: IVImage;
  informationMessage: any;
  contentfulPreviewEntryId?: string;
  contentfulPreviewHeadingFieldId?: string;
  contentfulPreviewUspsFieldId?: "usps" | "uspar";
}

const InsuranceNotCovered = ({
  heading,
  image,
  informationMessage,
  contentfulPreviewEntryId,
  contentfulPreviewHeadingFieldId,
  contentfulPreviewUspsFieldId,
}: IInsuranceNoAudience) => {
  return (
    <InformationArea
      heading={heading}
      image={image}
      type={"notCovered"}
      informationMessageType="info"
      informationMessage={informationMessage}
      contentfulPreviewEntryId={contentfulPreviewEntryId}
      contentfulPreviewHeadingFieldId={contentfulPreviewHeadingFieldId}
      contentfulPreviewUspsFieldId={contentfulPreviewUspsFieldId}
    />
  );
};

export default InsuranceNotCovered;
