import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export const getShortDate = (date: Date): Array<string> => {
  dayjs.locale("sv");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return [dayjs.tz(date, "Europe/Stockholm").format("D MMMM YYYY").toLowerCase()];
};
