import { useEffect, useState } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import InsuranceInformationArea from "@components/InsuranceInformationArea/InsuranceInformationArea";
import InsuranceSector from "@components/InsuranceInformationArea/InsuranceSector/InsuranceSector";
import PageInformation from "@components/PageInformation/PageInformation";
import PrintButton from "@components/PrintButton";
import PageWrapper from "@containers/Layout/PageWrapper/PageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { PersonalInfo } from "types/PersonalInfo";
import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import {
  InsuranceClaimPageType_insuranceClaimPage,
  InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items,
  InsuranceClaimPageType_insuranceClaimPage_personalizedMessageFallback,
} from "./queries/contentfulTypes/InsuranceClaimPageType";

interface claimPageData extends InsuranceClaimPageType_insuranceClaimPage {
  insuranceName: string;
  latestReviewDate: string;
  pageUpdateDate: string;
}
interface IInsuranceClaimPage {
  pageData: claimPageData;
}

const InsuranceClaimPage = (props: any) => {
  const { pageData: insuranceClaimPage }: IInsuranceClaimPage = props;

  const imageData = insuranceClaimPage?.ivMainImage?.[0];

  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);
  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);

  const [userSector, setUserSector] = useState<PersonalInfo>(null);
  const [isCoveredByInsurance, setIsCoveredByInsurance] = useState(false);
  const [personalizedMessage, setPersonalizedMessage] = useState<
    Array<InsuranceClaimPageType_insuranceClaimPage_personalizedMessageFallback>
  >(
    insuranceClaimPage?.personalizedMessageFallback
      ? Array.of(insuranceClaimPage?.personalizedMessageFallback)
      : []
  );
  const [personalizedLinks, setPersonalizedLinks] = useState([]);
  const context: Context = { ...defaultContext };
  context.insuranceName = insuranceClaimPage?.insuranceName;

  const useReCapcha = shouldUseReCapcha(insuranceClaimPage?.mainAreaCollection?.items);
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(
    insuranceClaimPage?.mainAreaCollection?.items
  );

  const restrictedRichTextBlock = personalizedMessage?.length > 0 && (
    <CFContentArea
      desktopColumns={1}
      mobileColumns={1}
      tabletColumns={1}
      contentAreaItems={personalizedMessage}
      context={context}
    />
  );

  useEffect(() => {
    if (isLoading) return;
    //Check if insurance is covered based on secor and role choise
    setIsCoveredByInsurance(
      insuranceClaimPage?.availableForAudiencesCollection?.items?.some(
        (a: any) => a?.audianceId === selectedSector?.audienceId
      )
    );
    //Get personalized message for insurance picker
    const variantRichText =
      insuranceClaimPage?.personalizedMessageVariantsCollection?.items?.filter((a: any) =>
        a?.restrictedRichTextBlock?.audienceCollection?.items?.some(
          (f: any) => f?.audience?.audianceId === selectedSector?.audienceId
        )
      );
    if (variantRichText && variantRichText?.length > 0) {
      setPersonalizedMessage(variantRichText);
    } else {
      setPersonalizedMessage(
        insuranceClaimPage?.personalizedMessageFallback
          ? Array.of(insuranceClaimPage?.personalizedMessageFallback)
          : []
      );
    }
    setPersonalizedLinks(
      insuranceClaimPage?.personalizedClaimLinksCollection?.items?.filter(
        (link: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items) =>
          link?.audienceCollection?.items?.some(
            (f: any) => f?.audianceId === selectedSector?.audienceId
          )
      )
    );
    setUserSector(selectedSector);
  }, [isLoading, selectedSector]);

  const pageId = insuranceClaimPage?.linkedFrom?.pageCollection?.items?.find((item) => item).sys
    ?.id;
  return (
    <PageWrapper
      imageData={null}
      topImageText={""}
      columns={12}
      relatedContentHeading={insuranceClaimPage?.relatedContentHeading}
      relatedContentCollection={insuranceClaimPage?.relatedContentCollection}
      relatedContentSmallImages={insuranceClaimPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      contentfulPreviewEntryId={insuranceClaimPage.sys.id}
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
    >
      <div className={pageStyles.wrapperGrid}>
        <div className={pageStyles.headingGrid}>
          <Heading
            variant="h1"
            data-contentful-entry-id={insuranceClaimPage.sys.id}
            data-contentful-field-id="heading"
          >
            {insuranceClaimPage?.heading}
          </Heading>
          {insuranceClaimPage?.preamble && (
            <Text
              variant={"preamble"}
              children={insuranceClaimPage?.preamble}
              data-contentful-entry-id={insuranceClaimPage.sys.id}
              data-contentful-field-id="preamble"
            />
          )}
        </div>
        <InsuranceSector
          sector={userSector?.sector}
          role={userSector?.role}
          context={context}
          state="claim"
        />
        <InsuranceInformationArea
          state={"claim"}
          covered={isCoveredByInsurance}
          hasAudience={userSector}
          coveredHeading={insuranceClaimPage?.uspHeading}
          notCoveredHeading={insuranceClaimPage?.fallbackUspHeading}
          noAudienceFallbackHeading={"Till anmälan behövs"}
          bulletListItems={insuranceClaimPage?.usps}
          informationMessage={restrictedRichTextBlock && restrictedRichTextBlock}
          links={personalizedLinks}
          context={context}
          image={imageData}
          contentfulPreviewEntryId={insuranceClaimPage.sys.id}
        />
        {insuranceClaimPage?.mainAreaCollection?.items?.length > 0 && (
          <Grid alignItems="center">
            <Grid container desktop={8}>
              <CFContentArea
                desktopColumns={1}
                mobileColumns={1}
                tabletColumns={1}
                contentAreaItems={insuranceClaimPage?.mainAreaCollection?.items}
                maxWidth={getContentAreaMaxWidth(8)}
                context={context}
              />
            </Grid>
          </Grid>
        )}
        <Grid alignItems="center">
          <Grid container desktop={8}>
            <PrintButton />
            {(insuranceClaimPage?.latestReviewDate || insuranceClaimPage?.pageUpdateDate) && (
              <PageInformation
                pageId={pageId}
                latestReviewDate={insuranceClaimPage?.latestReviewDate}
                pageUpdateDate={insuranceClaimPage?.pageUpdateDate}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  );
};

export default InsuranceClaimPage;
