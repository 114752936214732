import {
  insuransSelectTracker,
  insuransSigningTracker,
} from "@components/ChatBoxComponents/InsurancePickerBlock/InsuransPickerTracker";
import TrackedLink from "@components/TrackedLink";
import { InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items } from "@containers/InsuranceClaimPage/queries/contentfulTypes/InsuranceClaimPageType";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";

import informationAreaStyles from "./InformationArea/InformationArea.module.css";

interface IInsuranceAreaLink {
  link?: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items;
  insurance?: string;
  state?: "about" | "claim";
  index?: number;
  dataTestId?: string;
  contentfulPreviewEntryId?: string;
  contentfulPreviewLinkHeadingFieldId?: string;
}

const InformationAreaLink = ({
  link,
  insurance,
  state,
  dataTestId,
  index,
  contentfulPreviewEntryId,
  contentfulPreviewLinkHeadingFieldId,
}: IInsuranceAreaLink) => {
  const url = link?.url;
  const linkVariant = link?.variant && link?.variant?.toLowerCase() === "knapp" ? "button" : "link";
  const linkType = getInternalOrExternalIcon(link?.url, link?.reference, link?.globalLinkItem);

  return (
    url && (
      <TrackedLink
        linkType={linkType}
        buttonStyle={index === 0 ? "primary" : "secondary"}
        linkText={link?.linkText}
        url={url}
        linkVariant={linkVariant}
        target={link?.newTab ? "_blank" : "_self"}
        onClick={() =>
          state === "about"
            ? insuransSelectTracker(insurance, "gå vidare")
            : insuransSigningTracker(insurance, "gå vidare")
        }
        dataTestId={dataTestId}
        className={informationAreaStyles.linkButton}
        dataContentfulEntryId={contentfulPreviewEntryId}
        dataContentfulFieldId={contentfulPreviewLinkHeadingFieldId}
      />
    )
  );
};

export default InformationAreaLink;
