import Link from "next/link";

import Heading from "@afa-shared/afa-components/dist/Heading";
import ItemBox from "@afa-shared/afa-components/dist/ItemBox";
import Text from "@afa-shared/afa-components/dist/Text";
import IvHeroImage from "@components/Medias/Image/IvHeroImage";
import { ShortCutsBlockType_shortCutsBlock_linksCollection_items } from "@components/ShortCutsBlock/queries/contentfulTypes/ShortCutsBlockType";
import { shortCutsTracker } from "@components/ShortCutsBlock/shortCutsTracker";
import { getHeroShortcutLinkColor } from "@utils/pageHelpers/heroHelper";

import { HeroBlockType } from "../../types/HeroBlock";
import heroBlockStyles from "./HeroBlock.module.css";

interface IHeroProps {
  heroBlock: HeroBlockType;
}

const HeroBlock = ({ heroBlock }: IHeroProps) => {
  return (
    <div className={heroBlockStyles.heroBlock} id={"innehall"}>
      <div className={heroBlockStyles.grid}>
        <div className={heroBlockStyles.contentWrapper}>
          <div
            className={`${heroBlockStyles.mobileWrapper} ${
              heroBlock?.ingress ? heroBlockStyles.hasPreamble : ""
            }`}
          >
            <div className={heroBlockStyles.mobileHeadingWrapper}>
              <div className={heroBlockStyles.tabletHeadingWrapper}>
                <Heading
                  variant="h1"
                  children={heroBlock?.heading}
                  removeMargin
                  className={heroBlockStyles.heading}
                  data-contentful-entry-id={heroBlock?.contentfulPreviewEntryId}
                  data-contentful-field-id="heading"
                />
                {heroBlock?.ingress && (
                  <Text
                    variant={"preamble"}
                    children={heroBlock?.ingress}
                    removeMargin
                    className={heroBlockStyles.text}
                    data-contentful-entry-id={heroBlock?.contentfulPreviewEntryId}
                    data-contentful-field-id="preamble"
                  />
                )}
              </div>
              <IvHeroImage
                image={heroBlock?.image?.imageBlock?.ivImage?.[0]}
                className={heroBlockStyles.ivHeroImage}
                contentfulPreviewEntryId={heroBlock?.image?.imageBlock?.sys?.id}
                contentfulPreviewImageFieldId={"ivImage"}
              />
            </div>
            <div className={heroBlockStyles.mobilePreamble}>
              {heroBlock?.ingress && (
                <Text
                  variant={"preamble"}
                  children={heroBlock?.ingress}
                  removeMargin
                  className={heroBlockStyles.text}
                  data-contentful-entry-id={heroBlock?.contentfulPreviewEntryId}
                  data-contentful-field-id="preamble"
                />
              )}
            </div>
          </div>
          <div className={heroBlockStyles.linksWrapper}>
            <div className={heroBlockStyles.desktopWrapper}>
              <Heading
                variant="h1"
                children={heroBlock?.heading}
                removeMargin
                className={heroBlockStyles.heading}
                data-contentful-entry-id={heroBlock?.contentfulPreviewEntryId}
                data-contentful-field-id="heading"
              />
              {heroBlock?.ingress && (
                <Text
                  variant={"preamble"}
                  children={heroBlock?.ingress}
                  removeMargin
                  className={heroBlockStyles.text}
                  data-contentful-entry-id={heroBlock?.contentfulPreviewEntryId}
                  data-contentful-field-id="preamble"
                />
              )}
            </div>

            <ul
              className={heroBlockStyles.list}
              data-blockname="snabblänkar"
              data-contentful-entry-id={heroBlock?.shortcutLinks?.shortCutsBlock?.id}
              data-contentful-field-id="name"
            >
              {heroBlock?.shortcutLinks?.shortCutsBlock?.linksCollection?.items?.map(
                (item: ShortCutsBlockType_shortCutsBlock_linksCollection_items, index: number) => {
                  const itemUrl = item?.url;
                  const icon = item?.shortcutIcon ? `/images/icons/${item?.shortcutIcon}.svg` : "";
                  return (
                    <li key={index}>
                      <Link
                        href={itemUrl}
                        prefetch={false}
                        className={heroBlockStyles.link}
                        onClick={() => shortCutsTracker(item?.linkText)}
                      >
                        <ItemBox
                          icon={icon}
                          iconBackground={getHeroShortcutLinkColor(index)}
                          label={item?.linkText}
                          narrow
                          removeContentBorder
                          className={heroBlockStyles.itembox}
                        />
                      </Link>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div className={heroBlockStyles.desktopWrapper}>
            <IvHeroImage
              image={heroBlock?.image?.imageBlock?.ivImage?.[0]}
              className={heroBlockStyles.ivHeroImage}
              contentfulPreviewEntryId={heroBlock?.image?.imageBlock?.sys?.id}
              contentfulPreviewImageFieldId={"ivImage"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroBlock;
