import { IVImage } from "@utils/imageHelpers/imageHelper";

import InformationArea from "../InformationArea/InformationArea";

export interface IInsuranceNoAudience {
  heading: string;
  image: IVImage;
  bulletListItems: string[];
  contentfulPreviewEntryId?: string;
  contentfulPreviewHeadingFieldId?: string;
  contentfulPreviewUspsFieldId?: "usps" | "uspar";
}

const InsuranceNoAudience = ({
  heading,
  image,
  bulletListItems,
  contentfulPreviewEntryId,
  contentfulPreviewHeadingFieldId,
  contentfulPreviewUspsFieldId,
}: IInsuranceNoAudience) => {
  return (
    <InformationArea
      heading={heading}
      image={image}
      bulletListType="check"
      bulletListItems={bulletListItems}
      type={"noAudience"}
      contentfulPreviewEntryId={contentfulPreviewEntryId}
      contentfulPreviewHeadingFieldId={contentfulPreviewHeadingFieldId}
      contentfulPreviewUspsFieldId={contentfulPreviewUspsFieldId}
    />
  );
};

export default InsuranceNoAudience;
