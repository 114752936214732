import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Tag from "@afa-shared/afa-components/dist/Tag";
import Text from "@afa-shared/afa-components/dist/Text";
import RichTextRenderer from "@components/RichTextRenderer";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { getShortDate } from "@utils/dateHelpers/getShortDate";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import { ReportPageType_reportpage } from "./queries/contentfulTypes/ReportPageType";

export interface ExtendedReportPage extends ReportPageType_reportpage {
  latestReviewDate: string;
  pageUpdateDate: string;
}

const ReportPage = (props: any) => {
  const reportPage = props.pageData as ExtendedReportPage;
  const publishDate = getShortDate(reportPage?.publishDate);
  const imageData = reportPage?.ivMainImage?.[0];
  const context: Context = { ...defaultContext };

  const useReCapcha = shouldUseReCapcha(reportPage?.mainAreaCollection?.items);
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(reportPage?.mainAreaCollection?.items);

  return (
    <ArticlePageWrapper
      imageData={imageData}
      topImageText={reportPage?.topImageText}
      columns={8}
      relatedContentHeading={reportPage?.relatedContentHeading}
      relatedContentCollection={reportPage?.relatedContentCollection}
      relatedContentSmallImages={reportPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      contentfulPreviewEntryId={reportPage.sys.id}
      contentfulPreviewImageFieldId={"ivMainImage"}
      contentfulPreviewImageTextFieldId={"topImageText"}
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
    >
      <Grid desktop={8}>
        <Heading
          variant="h1"
          data-contentful-entry-id={reportPage.sys.id}
          data-contentful-field-id="heading"
        >
          {reportPage?.heading}
        </Heading>
        <div className={pageStyles.tagAreaGrid}>
          <Tag
            label={reportPage.reportType}
            data-contentful-entry-id={reportPage.sys.id}
            data-contentful-field-id="reportType"
          />
          <Text
            variant="span"
            data-contentful-entry-id={reportPage.sys.id}
            data-contentful-field-id="publishDate"
          >
            Publicerad {publishDate}
          </Text>
        </div>
        {reportPage?.preamble && (
          <Text
            variant={"preamble"}
            children={reportPage?.preamble}
            data-contentful-entry-id={reportPage.sys.id}
            data-contentful-field-id="preamble"
          />
        )}

        <div className={pageStyles.contentAreaGrid}>
          <div data-contentful-entry-id={reportPage.sys.id} data-contentful-field-id="mainBody">
            <RichTextRenderer
              json={reportPage?.mainBody?.json}
              links={reportPage?.mainBody?.links}
              pageTextArea
            />
          </div>

          {reportPage?.reportsAndFilesCollection?.items?.length > 0 && (
            <div>
              {reportPage?.reportsHeading && (
                <Heading
                  variant="h2"
                  data-contentful-entry-id={reportPage.sys.id}
                  data-contentful-field-id="reportsHeading"
                >
                  {reportPage?.reportsHeading}
                </Heading>
              )}
              <CFContentArea
                desktopColumns={1}
                mobileColumns={1}
                tabletColumns={1}
                key="report-area"
                contentAreaItems={reportPage?.reportsAndFilesCollection?.items}
                maxWidth={getContentAreaMaxWidth(8)}
                heading={reportPage?.reportsHeading && "h3"}
                context={context}
              />
            </div>
          )}
          <CFContentArea
            key="main-area"
            desktopColumns={1}
            mobileColumns={1}
            tabletColumns={1}
            contentAreaItems={reportPage?.mainAreaCollection?.items}
            maxWidth={getContentAreaMaxWidth(8)}
            context={context}
          />
        </div>
      </Grid>
    </ArticlePageWrapper>
  );
};

export default ReportPage;
